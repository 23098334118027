import classNamesFn from 'classnames';
import React, { ComponentType } from 'react';
import { ResponsiveContainerProps } from '@wix/thunderbolt-components';
import { inlinePopupSkinProps } from '../../MenuContainer.types';
import ResponsiveContainer from '../../../../thunderbolt-core-components/ResponsiveContainer/viewer/ResponsiveContainer';
import styles from './ResponsiveInlinePopupWithColorBG.scss';

const ResponsiveInlinePopupWithColorBG: ComponentType<inlinePopupSkinProps & {
  containerProps: ResponsiveContainerProps;
}> = ({ classNames, layerIds, containerProps, children }) => {
  return (
    <React.Fragment>
      <div
        id={layerIds.overlay}
        className={classNamesFn(styles.overlay, {
          [styles.horizontallyDocked]: classNames.includes(
            'horizontallyDocked',
          ),
        })}
      />
      <div id={layerIds.container} className={classNamesFn(styles.container)}>
        <div className={`${styles.background}`} />
        <div id={layerIds.inlineContentParent}>
          <ResponsiveContainer {...containerProps}>
            {children}
          </ResponsiveContainer>
        </div>
      </div>
    </React.Fragment>
  );
};

export default ResponsiveInlinePopupWithColorBG;
